var ScrollDetector = function () {
  "use strict";

  window.addEventListener('scroll', function () {
    if (window.scrollY > 0) {
      document.body.classList.add('scrolled');
    } else {
      document.body.classList.remove('scrolled');
    }
  });
};

new ScrollDetector();

function titleAnimation() {
  const titleAnim = document.querySelector(".mainTitle");
  const strTitleAnim = titleAnim.textContent;
  const splitTitleAnim = strTitleAnim.split("");

  titleAnim.textContent = "";

  for (let i = 0; i < splitTitleAnim.length; i++) {
    titleAnim.innerHTML += "<span>" + splitTitleAnim[i] + "</span>";
  }

  let char = 0;
  let timer = setInterval(onTick, 50);

  function onTick() {
    const span = titleAnim.querySelectorAll("span")[char];
    span.classList.add('fade');
    char++;
    if (char === splitTitleAnim.length) {
      complete();
      return;
    }
  }

  function complete() {
    clearInterval(timer);
    timer = null;
  }
}

function slider() {
  var slideIndex = 1;

  const navSliderItem = document.getElementsByClassName("js-nav-slider");
  const navSliderDot = document.getElementsByClassName("js-nav-dot");
  const prevSlide = document.getElementById("js-nav-prev");
  const nextSlide = document.getElementById("js-nav-next");

  prevSlide.addEventListener("click", () => {
    plusSlides(-1);
  });

  nextSlide.addEventListener("click", () => {
    plusSlides(1);
  });

  for (let i = 0; i < navSliderItem.length; i++) {
    navSliderItem[i].addEventListener("click", () => {
      currentSlide(i + 1);
    });
  }

  for (let i = 0; i < navSliderDot.length; i++) {
    navSliderDot[i].addEventListener("click", () => {
      currentSlide(i + 1);
    });
  }

  showSlides(slideIndex);

  function plusSlides(n) {
    showSlides(slideIndex += n);
  }

  function currentSlide(n) {
    showSlides(slideIndex = n);
  }

  function showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("slide");
    var dots = document.getElementsByClassName("dot");
    var tags = document.getElementsByClassName("tag");

    if (n > slides.length) {
      slideIndex = 1;
    }

    if (n < 1) {
      slideIndex = slides.length
    }

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" activeD", "");
    }
    for (i = 0; i < tags.length; i++) {
      tags[i].className = tags[i].className.replace(" active", "");
    }
    slides[slideIndex - 1].style.display = "flex";
    dots[slideIndex - 1].className += " activeD";
    tags[slideIndex - 1].className += " active";
  }
}

function contactForm() {
  var form = document.getElementById("contact-form");

  const handleSubmit = (e) => {
    e.preventDefault()
    const myForm = document.getElementById('contact-form');
    const successMessage = document.getElementById("form-status");
    const formData = new FormData(myForm)

    fetch('/', {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams(formData).toString()
    }).then(() => {
      successMessage.innerHTML = "Message envoyé avec succès";
      document.getElementById("js-input-company").value = "";
      document.getElementById("js-input-name").value = "";
      document.getElementById("js-input-email").value = "";
      document.getElementById("js-input-content").value = "";
      successMessage.classList.add('succes')
    }).catch((e) => {
      successMessage.innerHTML = "Une erreur est survenue ! Merci de ré-essayer.";
      successMessage.classList.add("error");
    });
  }

  form.addEventListener("submit", handleSubmit);
}


function clickAndDrag() {

  const slider = document.querySelector('.boxes');
  const sliderElements = document.querySelectorAll('.projImg, .projImg1');
  let isDown = false;
  let startX;
  let scrollLeft;

  for (let i = 0; i < sliderElements.length; i++) {
    sliderElements[i].addEventListener('mousedown', (e) => {
      sliderElements[i].classList.add('active')
    });
    sliderElements[i].addEventListener('mouseleave', () => {
      sliderElements[i].classList.remove('active');
    });
    sliderElements[i].addEventListener('mouseup', () => {
      sliderElements[i].classList.remove('active');
    });
  };

  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX);
    slider.scrollLeft = scrollLeft - walk;
    console.log(walk);
  });
}


function SmoothVerticalScrolling(e, time, where) {
  var eTop = e.getBoundingClientRect().top;
  var eAmt = eTop / 100;
  var curTime = 0;
  while (curTime <= time) {
    window.setTimeout(SVS_B, curTime, eAmt, where);
    curTime += time / 100;
  }
}

function SVS_B(eAmt, where) {
  if (where == "center" || where == "")
    window.scrollBy(0, eAmt / 2);
  if (where == "top")
    window.scrollBy(0, eAmt);
}


titleAnimation();
contactForm();
slider();
clickAndDrag();
SmoothVerticalScrolling();
SVS_B();